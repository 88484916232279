import { createSlice } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { fetchTokenBalance } from '../web3/account';
import { useOrdersActions, useTradesActions } from '../web3/orders';
import { useAccount } from './account';

export const exchangeSlice = createSlice({
    name: 'exchange',
    initialState: {
        base: null,
        quote: null,
        baseBalance: 0,
        quoteBalance: 0,
        orders: [],
        trades: []
    },
    reducers: {
        setBase: (state, action) => {
            state.base = action.payload;
        },

        setQuote: (state, action) => {
            state.quote = action.payload;
        },

        setBaseBalance: (state, action) => {
            state.baseBalance = action.payload;
        },

        setQuoteBalance: (state, action) => {
            state.quoteBalance = action.payload;
        },

        setOrders: (state, action) => {
            state.orders = action.payload;
        },

        setTrades: (state, action) => {
            state.trades = action.payload;
        },

        addTrade: (state, action) => {
            state.trades.push(action.payload);
        },

        updateTrade: (state, action) => {
            const idx = state.trades.findIndex(item => item.id === action.payload.id);
            state.trades[idx] = action.payload;
        },

        addOrder: (state, action) => {
            state.orders.push(action.payload);
        },

        removeOrder: (state, action) => {
            const index = state.orders.findIndex(item => item.id === action.payload);
            state.orders.splice(index, 1);
        },

        updateOrder: (state, action) => {
            const idx = state.orders.findIndex(item => item.id === action.payload.id);
            state.orders[idx] = action.payload;
        }
    },
});

export const { setBase, setQuote, setBaseBalance, setQuoteBalance, removeOrder, setOrders, addOrder, setTrades, updateOrder, addTrade, updateTrade } = exchangeSlice.actions;

export const useExchangeConfiguration = () => {
    const selector = useSelector(state => ({
        base: state.exchange.base,
        quote: state.exchange.quote
    }));

    return selector;
}

export const useExchangeBalances = () => {
    const { base, quote } = useExchangeConfiguration();
    const account = useAccount();
    const [, refresh] = useRefreshBalances(account, base?.address, quote?.address, base?.chainId);

    const selector = useSelector(state => ({
        baseBalance: state.exchange.baseBalance,
        quoteBalance: state.exchange.quoteBalance
    }));

    useEffect(() => {
        refresh()
    }, [refresh])

    return [selector, refresh];
}

export const useUpdateConfiguration = () => {
    const dispatch = useDispatch();
    const update = (base, quote) => {
        dispatch(setBase(base));
        dispatch(setQuote(quote));
    }
    return update;
}

export const useAccountOrders = (page = 0, limit = 6) => {
    const account = useAccount();
    const [, refresh] = useRefreshOrders();

    const selector = useSelector(state => {
        const _orders = state.exchange.orders;
        const orders = _orders.slice(page * limit, (page + 1) * limit);
        const pageCount = Math.floor(_orders.length / limit);
        return ({
            orders,
            page,
            limit,
            pageCount
        })
    });

    useEffect(() => {
        refresh(account);
    }, [account, refresh, page, limit]);

    return selector;
}

export const useAccountTrades = (page = 0, limit = 6) => {
    const account = useAccount();
    const [, refresh] = useRefreshTrades();

    const selector = useSelector(state => {

        // console.log("STATE UPDATED", state.exchange.trades);
        const trades = state.exchange.trades.slice(page * limit, (page + 1) * limit);
        const pageCount = Math.floor(state.exchange.trades.length / limit);
        return ({
            trades,
            page,
            limit,
            pageCount
        })
    });

    useEffect(() => {
        refresh(account);
    }, [account, refresh, page, limit]);

    return selector;
}

export const useAddTrade = () => {
    const dispatch = useDispatch();
    const { save } = useTradesActions();

    const add = async (trade) => {
        const id = await save(trade);
        dispatch(addTrade({ ...trade, id }))
    }

    return add;
}

export const useUpdateTrade = () => {
    const dispatch = useDispatch();
    const { update } = useTradesActions();

    const _update = async (order) => {
        await update(order);
        dispatch(updateTrade(order))
    }

    return _update;
}

export const useAddOrder = () => {
    const dispatch = useDispatch();
    const { save } = useOrdersActions();

    const add = async (order) => {
        const id = await save(order);
        dispatch(addOrder({ ...order, id }))
    }
    return add;
}


export const useRemoveOrder = () => {
    const dispatch = useDispatch();
    const { remove } = useOrdersActions();

    const _remove = async (id) => {
        // console.log("removing order", id);
        await remove(id);
        dispatch(removeOrder(id))
    }

    return _remove;
}

export const useUpdateOrder = () => {
    const dispatch = useDispatch();
    const { update } = useOrdersActions();

    const _update = async (order) => {
        await update(order);
        dispatch(updateOrder(order))
    }

    return _update;
}

export const useRefreshOrders = () => {
    const dispatch = useDispatch();
    const { get } = useOrdersActions();

    return useAsyncFn(async (account) => {
        const orders = await get(account);

        dispatch(setOrders(orders.reverse()));
    }, [dispatch, get]);
};

export const useRefreshTrades = () => {
    const dispatch = useDispatch();
    const { get } = useTradesActions();

    return useAsyncFn(async (account) => {
        console.log("ACCOUNT", account);
        if (!account) {
            dispatch(setTrades([]));
            return;
        }
        const trades = await get(account);
        // console.log(trades);
        dispatch(setTrades(trades.reverse()));
    }, [dispatch, get]);
};


export const useRefreshBalances = (account, baseAddress, quoteAddress, chainId) => {
    const dispatch = useDispatch();

    return useAsyncFn(async () => {
        const baseBalance = await fetchTokenBalance(baseAddress, account, chainId);
        const quoteBalance = await fetchTokenBalance(quoteAddress, account, chainId);
 
        dispatch(setBaseBalance(baseBalance));
        dispatch(setQuoteBalance(quoteBalance));

    }, [dispatch, baseAddress, quoteAddress, chainId, account]);
};




export default exchangeSlice.reducer;