import { configureStore } from '@reduxjs/toolkit';

import presaleReducer from './presale';
import exchangeReducer from './exchange';
import investorRewardsReducer from './investor-info';
import accountReducer from './account';
import fundInforeducer from './fund-info';
import bountyReducer from './bounty';
import stakingReducer from './staking';
import idoReducer from './idos';
import flashStakingReducer from './flash-staking';
import autoCompundRelockReducer from './autocompound-relock';
import vaultStakingReducer from './vault-staking';

const createStore = () => configureStore({
  reducer: {
    presale: presaleReducer,
    exchange: exchangeReducer,
    investorRewards: investorRewardsReducer,
    account: accountReducer,
    fundInfo: fundInforeducer,
    bountyInfo: bountyReducer,
    stakingInfo: stakingReducer,
    idos: idoReducer,
    flashStaking: flashStakingReducer,
    vaultStaking: vaultStakingReducer,
    autoCompoundRelock: autoCompundRelockReducer,
  },
  devTools: process.env.NODE_ENV !== 'production'
});

export default createStore;