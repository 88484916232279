import { ethers } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';
import VaultsData from "hpay/content/Vaults.json";
import { useCallback, useState } from 'react';
import * as Web3 from 'web3/dist/web3.min.js';
import { useAccount } from '../state/account';
import { useTokenContractWithProvider } from './contracts/contracts';
import { injectedProviderInit } from "./providers/injected";
import { walletConnectProviderInit } from "./providers/walletconnect";

export let TARGET_NETWORK = 'any';
export const SupportedProviders = {
    WALLET_CONNECT: 'wallet_connect',
    INJECTED: 'injected'
};

export const networks = {
    1: {                //Ethereum Mainnet
        name: 'eth',
        chainId: 1,
        chainName: 'Ethereum',
        shortName: 'ETH',
        currency: 'ETH',
        nativeToken: {
            chainId: 1,
            address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
            decimals: 18,
            symbol: "ETH",
            image: "/eth.svg"
        },
        usdToken: {
            chainId: 1,
            address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
            decimals: 18,
            symbol: "USDT",
            image: "/usdt.svg"
        },
        explorer: "https://etherscan.io/",
        rpcUrls: ['https://rpc.ankr.com/eth']
    },
    4: {                //Ethereum Mainnet
        name: 'teth',
        chainId: 4,
        chainName: 'Rinkebey',
        shortName: 'ETH',
        currency: 'ETH',
        nativeToken: {
            chainId: 4,
            address: "0xc778417e063141139fce010982780140aa0cd5ab",
            decimals: 18,
            symbol: "ETH",
            image: "/eth.svg"
        },
        testnet: true,
        explorer: "https://bscscan.com/",
        rpcUrls: ['https://rpc.ankr.com/eth_rinkeby']
    },
    56: {               //Binance Smart Chain
        name: 'bsc',
        chainId: 56,
        chainName: 'Binance Smart Chain',
        shortName: 'BSC',
        currency: 'BNB',
        nativeToken: {
            chainId: 56,
            address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
            decimals: 18,
            symbol: "BNB",
            image: "/bnb.svg"
        },
        usdToken: {
            chainId: 56,
            address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
            decimals: 18,
            symbol: "BUSD",
            image: "/busd.svg"
        },
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        // rpcUrls: ['https://bsc-dataseed1.ninicoin.io/'],
        explorer: "https://bscscan.com/",
        // rpcUrls: ['http://192.168.2.188:8545']

    },
    25: {               //Cronos Chain
        name: 'cronos',
        chainId: 25,
        chainName: 'Cronos Network',
        shortName: 'CRO',
        currency: 'CRO',
        nativeToken: {
            chainId: 25,
            address: "0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae23",
            decimals: 18,
            symbol: "CRO",
            image: "/cro.svg"
        },
        usdToken: {
            chainId: 25,
            address: "0x66e428c3f67a68878562e79a0234c1f83c208770",
            decimals: 18,
            symbol: "BNB",
            image: "/bnb.svg"
        },
        explorer: "https://cronoscan.com/",
        rpcUrls: ['https://evm.cronos.org']
    },
    97: {
        name: 'bsc_testnet',
        chainId: 97,
        chainName: 'Binance Smart Chain Testnet',
        shortName: 'TBSC',
        currency: 'tBNB',
        nativeToken: {
            chainId: 97,
            address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
            decimals: 18,
            symbol: "BNB",
            image: "/bnb.svg"
        },
        testnet: true,
        explorer: "https://testnet.bscscan.com/",
        rpcUrls: ["https://data-seed-prebsc-2-s2.binance.org:8545"]
    },
    338: {
        name: 'cro_testnet',
        chainId: 338,
        chainName: 'CRONOS Testnet',
        shortName: 'TCRO',
        currency: 'TCRO',
        testnet: true,

        nativeToken: {
            chainId: 338,
            address: "0x6a3173618859C7cd40fAF6921b5E9eB6A76f1fD4",
            decimals: 18,
            symbol: "TCRO",
            image: "/cro.svg"

        },
        explorer: "https://bscscan.com/",
        rpcUrls: ['https://cronos-testnet-3.crypto.org:8545']
    },

    43114: {               //Cronos Chain
        name: 'Avax',
        chainId: 43114,
        chainName: 'Avalanche C-Chain',
        shortName: 'AVAXC',
        currency: 'AVAX',
        nativeToken: {
            chainId: 43114,
            address: "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
            decimals: 18,
            symbol: "AVAX",
            image: "/avax.svg"
        },
        usdToken: {
            chainId: 43114,
            address: "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70",
            decimals: 18,
            symbol: "DAI",
            image: "/busd.svg"
        },
        explorer: "https://snowtrace.io/",
        rpcUrls: ['https://api.avax.network/ext/bc/C/rpc']
    },
    43113: {               //Cronos Chain
        name: 'Avax',
        chainId: 43113,
        testnet: true,
        chainName: 'Avalanche C-Chain Test',
        shortName: 'AVAXC',
        currency: 'AVAX',
        nativeToken: {
            chainId: 43113,
            address: "0xd00ae08403B9bbb9124bB305C09058E32C39A48c",
            decimals: 18,
            symbol: "AVAX",
            image: "/avax.svg"

        },
        explorer: "https://snowtrace.io/",
        rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc']
    },
    1116: {               //Core Chain
        name: 'Core',
        chainId: 1116,
        testnet: true,
        chainName: 'Core DAO',
        shortName: 'CORE',
        currency: 'CORE',
        nativeToken: {
            chainId: 1116,
            address: "0xd00ae08403B9bbb9124bB305C09058E32C39A48c",
            decimals: 18,
            symbol: "CORE",
            image: "/avax.svg"

        },
        explorer: "https://scan.coredao.org",
        rpcUrls: ['https://rpc.coredao.org/']
    }
};

window.web3 = new Web3(networks[56].rpcUrls[0]);

const ProviderMap = {
    WALLET_CONNECT: walletConnectProviderInit,
    INJECTED: injectedProviderInit
};

export const disconnectWallet = async () => {
    if (window.web3 && window.web3.currentProvider.close) {
        window.web3.currentProvider.close().catch(console.log);
    }

    Object.values(window.connectListeners).forEach(callback => {
        callback(false);
    });
};

export const connectWallet = async (providerName) => {
    await disconnectWallet();

    await ProviderMap[providerName]();
    await initializeContracts(window.web3);
    Object.values(window.connectListeners).forEach(callback => {
        callback(true);
    });
};

export const updateWeb3Provider = (curNetworkId) => {
    if (networks[curNetworkId]) {
        const networkRpc = networks[curNetworkId].rpcUrls[0];
        window.web3 = new Web3(networkRpc);
    }
}

const init = async () => {
    await disconnectWallet();

    if (window.ethereum) {

        window.injectedProvider.ethereum = window.ethereum;
        window.injectedProvider.web3 = window.web3;

        window.web3 = new Web3(window.injectedProvider.ethereum);

        if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (account) {
                Object.values(window.accountListeners).forEach(listener => listener(account[0]));
            });

            window.ethereum.on('chainChanged', function (networkId) {
                Object.values(window.networkChangeListeners).forEach(listener => listener('' + parseInt(networkId)));
            });

            window.ethereum.on('disconnect', () => {
                Object.values(window.connectListeners).forEach((callback) => {
                    callback(false);
                });
            });

        }
        if (window.web3) {
            initializeContracts(window.web3);
        }

    }
};

export const currentProviderIsMetamask = () => {
    if (typeof window !== 'undefined') {
        return window.web3.currentProvider.isMetaMask;
    }
};

export const getAccount = async () => {
    return window.web3 && window.web3.eth && window.web3.eth.getAccounts().then(accounts => {
        return accounts[0];
    });
};

export const isWalletUnlocked = async () => {
    if (window.ethereum && window.web3.eth) {
        const unlocked = await window.web3.eth.getAccounts().then(accounts => {
            return !!accounts && !!accounts[0];
        });
        return unlocked;
    }
    return false;
};

export const getNetworkId = async () => window.web3 && window.web3.eth && window.web3.eth.net && window.web3.eth.net.getId();

export const subscribeToNeworkChange = (id, callback) => {
    window.networkChangeListeners[id] = callback;
    getNetworkId().then(nId => {
        callback('' + nId);
    });

    setInterval(() => {
        getNetworkId().then(nid => {
            if (nid) {
                // updateWeb3Provider(nid);
                callback('' + nid);
            }
        });
    }, 2000);
};

export const subscribeToTargetNeworkChange = (id, callback) => {
    window.targetNetworkChangeListeners[id] = callback;
    callback(TARGET_NETWORK);
};

export const getBlockTime = async (provider) => {
    return (provider || window.web3).eth.getBlock("latest").then(data => data.timestamp * 1000);
};


export const subscribeToAccountChange = (id, callback) => {
    window.accountListeners[id] = callback;
    getAccount().then(account => {
        if (account) {
            callback(account);
        }
    });
};

export const subscribeToNeworkConnect = (id, callback) => {
    window.connectListeners[id] = callback;
};

const PROVIDERS = {};

export const getProvider = (networkId) => {
    if (PROVIDERS[networkId]) {
        return PROVIDERS[networkId];
    }

    try {
        PROVIDERS[networkId] = new Web3(networks[networkId].rpcUrls[0]);
        return PROVIDERS[networkId];

    } catch (error) {
        console.log("Coould not get provider with id:::", networkId)
    }
};


export const createVaultWeb3 = (address) => {
    let curVault = Object.values(VaultsData).find(item => item.address === address);
    let chainId = curVault.chainId;
    return getProvider(chainId);
};


export const addChain = async (chainId) => {
    if (window.ethereum) {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{
                    chainId: '0x' + parseInt(chainId).toString(16),
                }]
            });
        } catch (error) {
            if (error.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            chainId: '0x' + parseInt(chainId).toString(16),
                            chainName: networks[chainId].chainName,
                            nativeCurrency: {
                                name: networks[chainId].chainName,
                                symbol: networks[chainId].currency,
                                decimals: 18
                            },
                            rpcUrls: networks[chainId].rpcUrls
                        }]
                    });
                } catch (addError) {
                    console.log(addError);
                }
            }
        }
    }
};

export const useFaucet = (tokenAddress) => {
    const account = useAccount();
    const token = useTokenContractWithProvider(tokenAddress);

    const mint = useCallback(async () => {
        if (!token || !account) {
            return;
        }

        const decimals = token.decimals;
        await token.contract.write({
            method: 'mint', account, values: 0, params: [
                account,
                parseUnits('1000', decimals)
            ]
        }).catch(console.log)
    }, [token, account])

    return { mint };
}


export const useTargetNetwork = () => {
    const [network, setNetwork] = useState(TARGET_NETWORK);

    const setTargetNetwork = useCallback((networkId) => {
        if (networkId === 'any' || networks[networkId]) {
            setNetwork(networkId);
            TARGET_NETWORK = networkId;
            if (!window.targetNetworkChangeListeners) {
                return;
            }
            Object.values(window.targetNetworkChangeListeners).forEach(cb => cb(TARGET_NETWORK));
        }
    }, [setNetwork]);

    return { network, setTargetNetwork };

}

export const useTargetNetworkProvider = () => {
    return getProvider(TARGET_NETWORK);
}

async function initializeContracts(web3) {
    if (!web3) {
        return;
    }

}


if (typeof window !== 'undefined') {
    window.injectedProvider = {
        ethereum: null,
        web3: null
    };

    window.connectListeners = {};
    window.accountListeners = {};
    window.networkChangeListeners = {};
    window.targetNetworkChangeListeners = {};

    updateWeb3Provider(56);
    init();
};


export const signAuthMessage = async () => {
    try {
        const provider = new ethers.providers.Web3Provider(window.injectedProvider.ethereum)
        const signer = provider.getSigner();
        const signature = await signer.signMessage("MgUwdFcOTjxniIplmpVzRxGObZBAcSXzDO1LJp5UA6WeQSvZ7602ookKsmxYSVnEgeT18zDc7vfljPNHpPc7TJDAw8jNMs5VsngE");
        return signature;
    } catch (error) {
        console.log(error)
    }
}